'use client';
import React, { useCallback, useMemo, useState } from 'react';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';
import merge from 'deepmerge';
import { twMerge } from 'tailwind-merge';

import { useWindowSize, ScreenBreakpoints } from '@bloom/ui/components/hooks/useWindowSize';
import { IconButton } from '@bloom/ui/components/IconButton';
import { CloseIcon } from '@bloom/ui/components/Icons/Close';

export enum ModalSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULLSCREEN = 'fullscreen',
}

interface IModalProps {
  className?: string;
  closeButtonClassName?: string;
  closeOnEsc?: boolean;
  closeOnOutsideClick?: boolean;
  'data-mode'?: string;
  'data-testid'?: string;
  onClose: () => void;
  open: boolean;
  overlayClassName?: string;
  root?: HTMLElement | undefined;
  size?: ModalSizeEnum;
  withCloseButton?: boolean;
}

export function useDialog(props: IModalProps) {
  const {
    className,
    closeButtonClassName,
    closeOnEsc = true,
    closeOnOutsideClick = false,
    onClose,
    open,
    overlayClassName,
    root,
    size = ModalSizeEnum.SMALL,
    withCloseButton = true,
  } = props;

  const dataTestId = props['data-testid'];
  const dataMode = props['data-mode'];

  const [labelId, setLabelId] = useState('');
  const [descriptionId, setDescriptionId] = useState('');

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose]
  );

  const data = useFloating({ onOpenChange: handleOpenChange, open });

  const { context } = data;

  const dismiss = useDismiss(context, {
    escapeKey: !!closeOnEsc,
    outsidePress: !!closeOnOutsideClick,
  });

  const role = useRole(context);

  const interactions = useInteractions([dismiss, role]);

  return useMemo(
    () => ({
      className,
      closeButtonClassName,
      'data-mode': dataMode,
      'data-testid': dataTestId,
      onClose,
      open,
      overlayClassName,
      root,
      ...interactions,
      ...data,
      descriptionId,
      labelId,
      setDescriptionId,
      setLabelId,
      size,
      withCloseButton,
    }),
    [
      className,
      closeButtonClassName,
      data,
      dataMode,
      dataTestId,
      descriptionId,
      interactions,
      labelId,
      onClose,
      open,
      overlayClassName,
      root,
      size,
      withCloseButton,
    ]
  );
}

type ContextType =
  | (ReturnType<typeof useDialog> & {
      setDescriptionId: React.Dispatch<React.SetStateAction<string>>;
      setLabelId: React.Dispatch<React.SetStateAction<string>>;
    })
  | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export const ModalProvider: React.FC<React.PropsWithChildren<IModalProps>> = (props) => {
  const { children, ...options } = props;
  const dialog = useDialog(options);

  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
};

export const ModalContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function ModalContent(props, propRef) {
    const {
      className,
      closeButtonClassName,
      context: floatingContext,
      'data-testid': dataTestId,
      onClose,
      overlayClassName,
      root,
      size,
      withCloseButton,
      ...context
    } = useDialogContext();

    const { isMounted, status } = useTransitionStatus(floatingContext, { duration: 200 });

    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const { children, ...floatingProps } = context.getFloatingProps(props);

    const { width = 0 } = useWindowSize();
    const modalSize = width < ScreenBreakpoints.MD ? 'fullscreen' : size;

    if (!isMounted) {
      return null;
    }

    return (
      <FloatingPortal root={root}>
        <FloatingOverlay
          className={twMerge('grid duration-200', overlayClassName)}
          data-testid={dataTestId}
          lockScroll
          style={{ backgroundColor: status === 'open' ? 'rgba(64,69,71,0.9)' : 'rgba(64,69,71,0)' }}
        >
          <FloatingFocusManager context={floatingContext}>
            <div
              className={twMerge(
                'z-40 flex max-w-full flex-col items-center justify-center',
                modalSize === ModalSizeEnum.FULLSCREEN ? 'p-0' : 'p-12'
              )}
              data-mode={context['data-mode']}
              style={{ gridArea: '1 / 1 / 2 / 2' }}
            >
              <div
                aria-describedby={context.descriptionId}
                aria-labelledby={context.labelId}
                ref={ref}
                {...merge(floatingProps, {
                  className: twMerge(
                    'relative max-w-full m-auto bg-white dark:bg-darker-grey dark:text-white p-12 duration-200 overflow-hidden',
                    status === 'open' ? 'scale-100 opacity-100' : 'scale-90 opacity-0',
                    modalSize === ModalSizeEnum.FULLSCREEN ? ['h-full w-full'] : '',
                    modalSize !== ModalSizeEnum.FULLSCREEN ? ['rounded-sm'] : '',
                    modalSize === ModalSizeEnum.SMALL ? ['w-150'] : '',
                    modalSize === ModalSizeEnum.MEDIUM ? ['w-200'] : '',
                    modalSize === ModalSizeEnum.LARGE ? ['w-[1120px]'] : '',
                    className
                  ),
                })}
              >
                {withCloseButton ? (
                  <IconButton
                    className={twMerge(
                      'absolute top-4 right-4 z-50 lg:top-8 lg:right-8',
                      closeButtonClassName
                    )}
                    data-testid="close-modal-button"
                    onClick={onClose}
                  >
                    <CloseIcon color="currentColor" />
                  </IconButton>
                ) : null}

                {props.children}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    );
  }
);

const Modal: React.FC<React.PropsWithChildren<IModalProps>> = (props) => {
  const { children, ...restProps } = props;
  return (
    <ModalProvider {...restProps}>
      <ModalContent>{children}</ModalContent>
    </ModalProvider>
  );
};

export { Modal };
