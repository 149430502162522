import React from 'react';

import { twMerge } from 'tailwind-merge';

import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../types/polymorphic-component';

export type MenuItemPropsType<T, C extends React.ElementType> =
  | PolymorphicComponentPropWithRef<
      C,
      { asChild?: boolean; item: T | undefined; onClick: (item: T) => void }
    >
  | PolymorphicComponentPropWithRef<
      C,
      { asChild?: boolean; onClick?: React.ComponentProps<C>['onClick'] }
    >;

export type MenuItemComponentType = <T, C extends React.ElementType = 'p'>(
  props: MenuItemPropsType<T, C>
) => React.ReactNode | null;

export const MenuItem: MenuItemComponentType = React.forwardRef(
  <T, C extends React.ElementType = 'div'>(
    props: MenuItemPropsType<T, C>,
    forwardedRef?: PolymorphicRef<C>
  ) => {
    const { as: ComponentTag = 'div', asChild, className, ...restProps } = props;

    function handleClick(e: React.MouseEvent<C>) {
      if ('disabled' in props && props.disabled) {
        return;
      }

      if (typeof props.onClick === 'function') {
        if ('item' in props) {
          props.onClick(props.item);
          return;
        }

        props.onClick(e);
      }
    }

    const classNameProp = twMerge(
      'rounded-sm whitespace-nowrap flex gap-3 h-9 shrink-0 basis-8 items-center hover:bg-light-grey cursor-pointer outline-hidden px-3',
      props.disabled ? 'cursor-not-allowed text-grey' : '',
      className
    );

    if (asChild && React.isValidElement(props.children)) {
      return React.cloneElement(props.children, {
        className: twMerge(classNameProp, props.children.props.className),
        ref: forwardedRef,
      });
    }

    return (
      <ComponentTag
        ref={forwardedRef}
        {...(ComponentTag === 'button' && { type: 'button' })}
        {...restProps}
        className={classNameProp}
        onClick={handleClick}
      />
    );
  }
);
