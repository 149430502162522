import React from 'react';

import { IIconProps } from './types';

const TrashIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M8.333 2.5a.833.833 0 00-.833.833v.834h5v-.834a.833.833 0 00-.833-.833H8.333zm5.834 1.667v-.834a2.5 2.5 0 00-2.5-2.5H8.333a2.5 2.5 0 00-2.5 2.5v.834H2.5a.833.833 0 100 1.666h.833v10.834a2.5 2.5 0 002.5 2.5h8.334a2.5 2.5 0 002.5-2.5V5.833h.833a.833.833 0 000-1.666h-3.333zM5 5.833v10.834a.833.833 0 00.833.833h8.334a.833.833 0 00.833-.833V5.833H5z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { TrashIcon };
