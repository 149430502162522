'use client';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  Placement,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { flushSync } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '@bloom/ui/components/Icons/Check';
import { MenuItem } from '@bloom/ui/components/MenuItem';
import { Tooltip } from '@bloom/ui/components/Tooltip';

export type TDropdownCustomMenuItemProps<T = unknown> = IDropdownMenuItem<T> & {
  index: number;
  selected?: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLElement>, 'value'>;

export interface IDropdownMenuItem<T = unknown> {
  className?: string;
  data?: T;
  'data-testid'?: string;
  disabled?: boolean;
  disabledMessage?: string;
  filterable?: boolean;
  icon?: React.ReactElement;
  label: string;
  MenuItem?: React.ComponentType<TDropdownCustomMenuItemProps>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  value?: unknown;
}

const DropdownContext = React.createContext<{
  activeIndex: number | null;
  'data-testid': string;
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
  isOpen: boolean;
  name?: string;
  onChange?: (name: string, value: unknown) => void;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  value?: string;
}>({
  activeIndex: null,
  'data-testid': '',
  getItemProps: () => ({}),
  isOpen: false,
  name: undefined,
  onChange: undefined,
  setActiveIndex: () => {},
  value: undefined,
});

export const useDropdownContext = () => {
  const context = React.useContext(DropdownContext);

  if (context == null) {
    throw new Error('Components must be wrapped in <DropdownContext />');
  }

  return context;
};

interface DropdownProps {
  'data-testid'?: string;
  hideWithNoItems?: boolean;
  initialFocus?: number;
  inputValue?: string;
  invalid?: boolean;
  matchParentWidth?: boolean;
  menuClassName?: string;
  MenuItem?: React.ComponentType<TDropdownCustomMenuItemProps<any>>;
  menuItemClassName?: string;
  menuItems: Readonly<Array<IDropdownMenuItem>>;
  minMenuWidth?: number;
  name?: string;
  NoItemsElement?: React.ReactNode;
  onChange?: (name: string, value: string) => void;
  onOpenChange?: (isOpen: boolean) => void;
  placement?: Placement;
  root?: HTMLElement | null;
  Trigger: React.ReactElement;
  triggerEvent?: 'click' | 'hover';
  typeaheadEnabled?: boolean;
  value?: string;
  virtual?: boolean;
  visibleItems?: number;
}

export const DropdownMenuItem: React.FC<
  IDropdownMenuItem & { index: number; ref: React.RefObject<HTMLElement> } & Omit<
      React.ButtonHTMLAttributes<HTMLElement>,
      'value'
    >
> = (props) => {
  const {
    children,
    className,
    disabled,
    disabledMessage,
    icon,
    index,
    label,
    MenuItem: CustomMenuItem,
    ref,
    value,
    ...restProps
  } = props;

  const menuContext = useContext(DropdownContext);

  const item = useListItem({ label: disabled ? null : label });

  const tree = useFloatingTree();
  const isActive = index === menuContext.activeIndex;
  const isSelected = menuContext.value !== undefined && value === menuContext.value;

  const refs = useMergeRefs([item.ref, ref]);

  const menuItemProps = useMemo(
    () => ({
      'aria-selected': isSelected,
      'data-testid': props['data-testid'] || `${menuContext['data-testid']}-menu-item-${index}`,
      disabled: disabled,
      index,
      ref: refs,
      role: 'menuitem',
      selected: isSelected,
      tabIndex: isActive ? 0 : -1,
      type: 'button' as const,
      ...restProps,
      // Keep override after ...restProps
      ...menuContext.getItemProps({
        onClick(event: React.MouseEvent<HTMLButtonElement>) {
          event.stopPropagation();
          if (menuContext.onChange && value !== undefined) {
            menuContext.onChange(menuContext.name, value);
          }

          props.onClick?.(event);
          tree?.events.emit('click');
        },
        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
        },
      }),
    }),
    [
      isSelected,
      props,
      menuContext,
      index,
      disabled,
      refs,
      isActive,
      restProps,
      value,
      tree?.events,
    ]
  );

  return CustomMenuItem ? (
    <CustomMenuItem {...menuItemProps} icon={icon} label={label} value={value} />
  ) : (
    <Tooltip
      asChild
      className="flex w-full justify-between"
      content={disabled ? disabledMessage : ''}
      offsetProps={{ mainAxis: 20 }}
      placement="top-end"
    >
      <MenuItem
        as="button"
        data-testid={`${props['data-testid']}-menu-item-${index}`}
        {...menuItemProps}
        className={twMerge('w-full gap-3 font-medium', className)}
      >
        {children ?? (
          <>
            {React.isValidElement(icon)
              ? React.cloneElement(icon, { color: 'currentColor', width: 16, ...icon.props })
              : null}
            <span className="truncate">{label}</span>
            {isSelected ? (
              <CheckIcon className="ml-auto" color="var(--color-primary)" width={16} />
            ) : null}
          </>
        )}
      </MenuItem>
    </Tooltip>
  );
};

const MenuWrapper: React.FC<
  React.PropsWithChildren<{
    className?: string;
    'data-testid'?: string;
    floatingProps: Record<string, unknown>;
    floatingStyles: React.CSSProperties;
    maxHeight: number;
    virtual: boolean;
    virtualSize?: number;
    virtualWrapperRef: React.RefObject<HTMLDivElement>;
  }>
> = (props) => {
  const {
    children,
    className,
    floatingProps,
    floatingStyles,
    maxHeight,
    ref,
    virtual,
    virtualSize,
    virtualWrapperRef,
  } = props;

  if (virtual) {
    return (
      <div
        className={twMerge(
          'list-unstyled flex flex-col overflow-y-auto rounded-lg bg-white p-2 shadow-lg !outline-hidden empty:hidden',
          className
        )}
        data-testid={`${props['data-testid']}-listbox`}
        ref={ref}
        style={{ ...floatingStyles, maxHeight }}
        tabIndex={-1}
      >
        <div
          className="relative"
          data-testid={`${props['data-testid']}-menu`}
          ref={virtualWrapperRef}
          {...floatingProps}
          style={{ height: `${virtualSize}px` }}
        >
          {children}
        </div>
      </div>
    );
  }
  return (
    <div
      className={twMerge(
        'list-unstyled flex flex-col overflow-y-auto rounded-lg bg-white p-2 shadow-lg !outline-hidden empty:hidden',
        className
      )}
      data-testid={`${props['data-testid']}-menu`}
      ref={ref}
      style={floatingStyles}
      {...floatingProps}
    >
      {children}
    </div>
  );
};

export const DropdownComponent: React.FC<
  DropdownProps & Omit<React.HTMLProps<HTMLElement>, 'onChange'>
> = (props) => {
  const {
    children,
    className,
    disabled,
    initialFocus = 0,
    inputValue,
    matchParentWidth = true,
    menuClassName,
    MenuItem: CustomMenuItem,
    menuItemClassName,
    menuItems,
    minMenuWidth = 0,
    name,
    NoItemsElement,
    onChange,
    onOpenChange,
    open,
    placement,
    ref,
    root,
    Trigger,
    triggerEvent = 'click',
    typeaheadEnabled = true,
    value,
    virtual = false,
    visibleItems = 10,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const computedOpen = open ?? isOpen;
  const computedOpenChange = onOpenChange ?? setIsOpen;

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  // The initial max-height is what `react-virtual` uses to know how many
  // items to render. This needs to be a smaller value so it doesn't try
  // to render every single item on mount.
  const [maxHeight, setMaxHeight] = useState(400);

  const elementsRef = useRef<Array<HTMLElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  const parent = useContext(DropdownContext);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const { context, elements, floatingStyles, refs } = useFloating<HTMLElement>({
    middleware: [
      flip(),
      offset({ mainAxis: 12 }),
      shift(),
      size({
        apply({ availableHeight, elements, rects }) {
          flushSync(() => {
            setMaxHeight(availableHeight);
          });

          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, visibleItems * 32 + 16)}px`,
            width: matchParentWidth
              ? `${Math.max(minMenuWidth, 150, rects.reference.width)}px`
              : '',
          });
        },
      }),
    ],
    nodeId,
    onOpenChange: computedOpenChange,
    open: computedOpen,
    placement: placement || 'bottom-start',
    whileElementsMounted: autoUpdate,
  });

  const rootElement = useMemo(() => {
    return (
      root ??
      (elements.domReference?.closest('[data-floating-ui-portal]') as HTMLElement) ??
      elements.domReference?.ownerDocument.body
    );
  }, [root, elements.domReference]);

  const virtualizer = useVirtualizer({
    count: menuItems.length,
    estimateSize: () => 36,
    getScrollElement: () => (virtual ? refs.floating.current : null),
    overscan: 5,
  });

  const hover = useHover(context, {
    delay: { open: 75 },
    enabled: triggerEvent === 'hover',
    handleClose: safePolygon({ blockPointerEvents: true }),
  });
  const click = useClick(context, { enabled: !disabled });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, { bubbles: true });
  const listNavigation = useListNavigation(context, {
    activeIndex,
    listRef: elementsRef,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    activeIndex,
    enabled: typeaheadEnabled,
    listRef: labelsRef,
    onMatch: computedOpen ? setActiveIndex : undefined,
  });

  const { getFloatingProps, getItemProps, getReferenceProps } = useInteractions([
    hover,
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  React.useEffect(() => {
    if (!tree) {
      return;
    }

    function handleTreeClick() {
      computedOpenChange(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        computedOpenChange(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId, computedOpenChange]);

  useEffect(() => {
    if (computedOpen && tree) {
      tree.events.emit('menuopen', { nodeId, parentId });
    }
  }, [tree, computedOpen, nodeId, parentId]);

  // useLayoutEffect(() => {
  //   if (virtual && isPositioned) {
  //     // Nothing has been selected, reset scrolling upon open
  //     if (activeIndex === null) {
  //       virtualizer.scrollToIndex(0, { behavior: 'smooth' });
  //     }

  //     // Scrolling is restored, but the item will be scrolled
  //     // into view when necessary
  //     if (activeIndex !== null) {
  //       menuWrapperRef.current?.focus({ preventScroll: true });
  //       virtualizer.scrollToIndex(activeIndex, { behavior: 'smooth' });
  //     }
  //   }
  // }, [virtualizer, isPositioned, activeIndex, refs, virtual]);

  const contextValue = useMemo(
    () => ({
      activeIndex,
      'data-testid': restProps['data-testid'],
      getItemProps,
      isOpen: computedOpen,
      name,
      onChange,
      setActiveIndex,
      value,
    }),
    [activeIndex, computedOpen, getItemProps, name, onChange, restProps, value]
  );

  return (
    <FloatingNode id={nodeId}>
      <DropdownContext.Provider value={contextValue}>
        {React.isValidElement(Trigger)
          ? React.cloneElement(Trigger, {
              'data-open': computedOpen ? '' : undefined,
              'data-testid': `${restProps['data-testid']}-trigger`,
              ...getReferenceProps({
                ...parent.getItemProps({
                  ...restProps,
                  disabled,
                  onFocus(event: React.FocusEvent<HTMLButtonElement>) {
                    restProps.onFocus?.(event);
                  },
                }),
                onClick(event: React.MouseEvent<HTMLButtonElement>) {
                  event.stopPropagation();
                },
              }),
              ...Trigger.props,
              className: twMerge(Trigger.props?.className, className),
              // eslint-disable-next-line react-hooks/rules-of-hooks
              ref: useMergeRefs([refs.setReference, item.ref, ref, Trigger.props.ref]),
            })
          : null}

        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {computedOpen && (
            <FloatingPortal root={rootElement}>
              <FloatingFocusManager
                context={context}
                disabled={initialFocus === -1}
                initialFocus={initialFocus}
                modal={false}
                returnFocus
              >
                <MenuWrapper
                  className={menuClassName}
                  data-testid={props['data-testid']}
                  floatingProps={getFloatingProps()}
                  floatingStyles={floatingStyles}
                  maxHeight={maxHeight}
                  ref={refs.setFloating}
                  virtual={virtual}
                  virtualSize={virtual ? virtualizer.getTotalSize() : undefined}
                  virtualWrapperRef={menuWrapperRef}
                >
                  {React.isValidElement(children) ? (
                    React.cloneElement(children, { ...props })
                  ) : (
                    <>
                      {menuItems.length === 0 ? (
                        NoItemsElement === undefined ? (
                          <MenuItem
                            as="button"
                            className={twMerge(
                              'text-grey w-full cursor-default items-center gap-3 italic hover:bg-transparent',
                              menuItemClassName
                            )}
                            data-testid={`${props['data-testid']}-no-items`}
                          >
                            <span className="flex-1">No options found</span>
                          </MenuItem>
                        ) : null
                      ) : virtual ? (
                        virtualizer.getVirtualItems().map((virtualItem) => {
                          const { index } = virtualItem;
                          const item = menuItems[index]!;

                          return (
                            <DropdownMenuItem
                              MenuItem={CustomMenuItem}
                              className={menuItemClassName}
                              index={index}
                              key={virtualItem.key}
                              {...item}
                              style={{
                                ...(virtual
                                  ? {
                                      height: `${virtualItem.size}px`,
                                      left: 0,
                                      maxWidth: '100%',
                                      position: 'absolute',
                                      top: 0,
                                      transform: `translateY(${virtualItem.start}px)`,
                                    }
                                  : {}),
                              }}
                            />
                          );
                        })
                      ) : (
                        menuItems.map((item, index) => (
                          <DropdownMenuItem
                            MenuItem={CustomMenuItem}
                            className={menuItemClassName}
                            index={index}
                            key={item.value}
                            {...item}
                          />
                        ))
                      )}
                    </>
                  )}
                </MenuWrapper>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </DropdownContext.Provider>
    </FloatingNode>
  );
};

interface IProps extends DropdownProps {}

export const Dropdown: React.FC<
  IProps & { children?: React.ReactNode | ((props: IProps) => React.ReactNode) } & Omit<
      React.HTMLProps<HTMLButtonElement>,
      'onChange'
    >
> = (props) => {
  const { children, hideWithNoItems = true, NoItemsElement, ...restProps } = props;

  if (!children && hideWithNoItems && !props.menuItems.length) {
    return null;
  }

  return (
    <FloatingTree>
      <DropdownComponent {...restProps} NoItemsElement={NoItemsElement}>
        {children}
      </DropdownComponent>
    </FloatingTree>
  );
};
